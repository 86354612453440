<!--
 * @Author: Libra
 * @Date: 2021-06-01 11:11:28
 * @LastEditTime: 2021-07-26 16:29:19
 * @LastEditors: Libra
 * @Description: 人脸检测完成之后的返回页面
 * @FilePath: /stone-exam-ui/src/views/faceReturn.vue
-->
<template>
  <div class="common-bg container">
    <div class="content">
      <div class="left">
        <i class="iconfont iconkaohe"></i>
      </div>
      <div class="right">
        <span class="title">人脸识别验证通过</span>
        <span class="detail">请关闭弹窗，进行下一步操作</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  .content {
    box-shadow: 0 0 18px #ddd;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 120px;
    box-sizing: border-box;
    .left {
      .iconfont {
        font-size: 100px;
        color: #cb2a1d;
        margin-right: 20px;
      }
    }
    .right {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      .title {
        display: block;
        font-size: 24px;
        font-weight: bold;
        color: #cb2a1d;
      }
    }
  }
}
</style>
